import React, { useState, useContext } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, useDisclosure, Image } from "@chakra-ui/react";
import { SettingsIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";
import { useFactory } from "./hooks/useFactory";

import MintButton from "./components/MintButton";
import TokenSelect from "./components/TokenSelect";
import TokenSelect2 from "./components/TokenSelect2";
import TokenModal from "./components/TokenModal";
import CustomSwitchLarge  from "./components/CustomSwitchLarge";

import logo_cronos from "./assets/cronos_light_bg.png";
import logo_wcro from "./assets/WCRO.png";
import logo_cro from "./assets/CRO.png";
import logo_ptcro from "./assets/ptCRO.png";
import logo_ytcro from "./assets/ytCRO.png";
import logo_ptcrov2 from "./assets/ptCROv2.png";
import logo_ytcrov2 from "./assets/ytCROv2.png";

import { AppContext } from "./context";

function Mint() {
    const {
        Factory_ADDRESS,
        Factory_ABI,
        WCRO_address,
        web3Provider,
        amount,
        setAmount,
        isLoading,
        setIsLoading,
        walletAddress,
        selectedCoin,
        setSelectedCoin,
        blockNumber,
        color,
        color2
      } = useContext(AppContext);

      const { isOpen, onOpen, onClose } = useDisclosure();

      const { handleExplorer } = useWeb3();
      const { checkAllowance, approveToken } = usePAN();
      const { CircleIcon } = useIcon();
      const { mint_ptCROV2 } = useFactory();

      const [allowed, setAllowed] = useState(true);

      const [V1Checked, setV1Checked] = useState(false);

      const toggleSwitch = () => setV1Checked(false);

      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      const checkStatus = async (amount) => {
        console.log("check");
        if (web3Provider) {
          try {

            if (selectedCoin.symbol === "WCRO") {
              var result = await checkAllowance(WCRO_address, amount, Factory_ADDRESS);
              setAllowed( result ); 
            }
            else {
              setAllowed( true ); 
            }
            //console.log(amount, allowed, result, WCRO_address);
          } catch (error) {
            console.error(error);
          }
        }
      };

  return (
    <Box
    className="container"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                       : "92.3vh"}
    overflow={(isMobile) ? "scroll" : "hidden" }
    >
    <TokenModal isOpen={isOpen} onClose={onClose} />

    <Box>
      <Box
      w={(isMobile) ? "95vw" : "33rem"}
      mx="auto"
      position="relative"
      top={(isMobile) ? "110px" : "230px"}
      boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
      borderRadius="1.25rem">

        <Flex
          alignItems="center"
          p="1rem 1rem 1rem"
          bg="white"
          color="rgb(86, 90, 105)"
          justifyContent="space-between"
          borderRadius="1.25rem 1.25rem 0 0">

          <Flex alignItems="center">
            <Text
              color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
              fontWeight="bold"
              mr="4px">
              Mint ptCRO + ytCRO
            </Text>

            <Text
              color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
              fontWeight="bold">
              {V1Checked ? "V1" : "V2" }
            </Text>
          </Flex>

          <Flex alignItems="center">
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />

            <SettingsIcon
              ml="10px"
              fontSize="1.25rem"
              cursor="pointer"
              _hover={{ color: "rgb(128,128,128)" }}
            />
          </Flex>

        </Flex>

        <Box
          p="0rem 0.5rem 0.5rem 0.5rem"
          bg="white"
          borderRadius="0 0 0rem 0rem">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="rgb(247, 248, 250)"
            p="1rem 1rem 1rem"
            borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
            height="75px"
            _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
            <Box>
              <Text
                width="70px"
                color="black"
                fontWeight="bold">
                Amount:
              </Text>
            </Box>
            <Flex
              alignItems="center"
              justifyContent="center"
              bg="white"
              p="0.18rem"
              borderRadius="1.25rem"
              pos="absolute"
              top="7.8rem"
              left="50%"
              transform="translateX(-50%)"
              w="2rem">
              <ArrowDownIcon
                bg="rgb(247, 248, 250)"
                color="rgb(128,128,128)"
                h="1.5rem" width="1.62rem"
                borderRadius="1.25rem"
              />
            </Flex>
            <Box>
              <Input
                placeholder="0.0"
                fontWeight="500"
                fontSize="1.4rem"
                width="100%"
                size="19rem"
                textAlign="right"
                bg="rgb(247, 248, 250)"
                outline="none"
                border="none"
                focusBorderColor="none"
                borderRadius="0.25rem"
                type="text"
                inputMode="decimal"
                color="black"
                value={amount}
                style={{ boxShadow: "none" }}
                onChange={ (e)  => {const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount(newAmount); checkStatus(newAmount);} }
              />
            </Box>
            <Box ml="0.5rem">
            <TokenSelect openTokenModal={onOpen} value={selectedCoin.symbol} image={selectedCoin.icon}/>
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="rgb(247, 248, 250)"
            p="1rem 1rem 1rem"
            mt="0.25rem"
            borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
            height="75px"
            _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
            >
            <Box>
              <Text
                width="70px"
                color="black"
                fontWeight="bold">
                To:
              </Text>
            </Box>
            <Box>
              <Input
                placeholder="0.0"
                fontWeight="500"
                fontSize="1.4rem"
                width="100%"
                size="19rem"
                textAlign="right"
                bg="rgb(247, 248, 250)"
                outline="none"
                border="none"
                focusBorderColor="none"
                borderRadius="0.25rem"
                type="text"
                inputMode="decimal"
                color="black"
                value={amount}
                isDisabled
                style={{ boxShadow: "none", cursor: "auto" }}
              />
            </Box>
            <Box ml="0.5rem">
            <TokenSelect2 value={"+"} image={V1Checked ? logo_ptcro : logo_ptcrov2 } image2={V1Checked ? logo_ytcro : logo_ytcrov2 }/>
            </Box>
          </Flex>

        </Box>

        { (allowed===true)  && <MintButton onClick={mint_ptCROV2} isLoading={isLoading} text="Mint" allowed={allowed} /> }
        { (allowed===false) && <MintButton onClick={async () => { var tx = await approveToken(WCRO_address, Factory_ADDRESS, amount);
                                                                  if (typeof tx !== 'undefined') {
                                                                    setIsLoading(true);
                                                                    await tx.wait(); //await delay(25000); //Delay 25s
                                                                    setIsLoading(false);
                                                                    checkStatus(amount);
                                                                  }
                                                                }} isLoading={isLoading} text="Approve" allowed={allowed} /> }

      </Box>
      
    </Box>

    <Flex bottom="0" justify="space-between" alignItems="center" width="100vw" mb={(isAndroid) ? "1.6rem" : "0.2rem"}>

      <Flex mr="16px" w="90px">
      </Flex>

      <Flex alignItems="center">
        <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
        <Box width="85px" height="30px">
          <Image src={logo_cronos} fit="contain"/>
        </Box>
      </Flex>

      <Flex alignItems="center">
        {web3Provider && (
        <Flex alignItems="center">
        <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
        <CircleIcon boxSize={3} color={color} mr={(isMobile) ? '0px' : '16px'}/>
        </Flex>
        )}
        {!web3Provider && (
        <Flex alignItems="center">
        <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
        <CircleIcon boxSize={3} color="transparent" mr={(isMobile) ? '0px' : '16px'}/>
        </Flex>
        )}
      </Flex>

    </Flex>
    
    </Box>
  );
  
}

export default Mint;
